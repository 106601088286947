import { Injectable, NgZone, Output, EventEmitter } from "@angular/core";
import { environment } from "../../../environments/environment";
import * as CryptoJS from "crypto-js";
import { PaymentConfigModel } from "../models/payment-config.model";
import { Router } from "@angular/router";
import { PaymentTransactionModel } from "../models/paymentez/payment-transaction.model";
import { PaymentezRequestModel } from "../models/paymentez/paymentez-request.model";
import { UserCardPaymentezModel } from "../models/paymentez/user-card-paymentez.model";
import { PaymentTransactionService } from "./payment-transaction.service";
import { AuthModel } from "../models/auth.model";
import { SessionStorageService } from "./session-storage.service";
import { CreditCardPaymentezUtility } from "../utilities/credit-card-paymentez.utility";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackMessageComponent } from "src/app/commons/snack-message/snack-message.component";
import { v4 as uuid } from 'uuid';
import { AuthService } from './auth.service';
import { MatDialog } from "@angular/material/dialog";
import { PurchasePaymentMixComponent } from "src/app/gateway/paymentez/paymentez-dialog/pay-mixed/purchase-payment-mix/purchase-payment-mix.component";


// declare var PaymentezCheckout: any;
const url = environment.urlPaymentezCheckoutSDK;
const mode = environment.paymentezEnviromentMode;
declare var PaymentezCheckout: any;
declare var jQuery: any;
declare var $: any;

@Injectable({
  providedIn: "root"
})
export class PaymentezCheckoutService {
  loadAPI: Promise<any>;
  @Output() responsePointsColombiaCard: EventEmitter<any> = new EventEmitter();
  @Output() responseMixedPurchase: EventEmitter<any> = new EventEmitter();

  constructor(
    private ngZone: NgZone,
    protected _router: Router,
    private paymentTransactionService: PaymentTransactionService,
    private sessionStorageService: SessionStorageService,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    public dialog: MatDialog,
  ) { }

  inicializarPago(paymentRequest: PaymentConfigModel, debit = false, mixed_purchase = false) {
    this.loadScript();
    setTimeout(() => {
      PaymentezCheckout = new PaymentezCheckout.modal({
        client_app_code: paymentRequest.paymentes_credentials.code_app_client, // Client Credentials Provied by Paymentez
        client_app_key: paymentRequest.paymentes_credentials.secret_client_app, // Client Credentials Provied by Paymentez
        locale: "es", // User's preferred language (es, en, pt). English will be used by default.
        env_mode: mode, // `prod`, `stg` to change environment. Default is `stg`
        onOpen: function () { },
        onClose: function () { },

        onResponse: function (response) {
          // The callback to invoke when the Checkout process is completed
          let cardModel = response.card;
          const _wordsReference = CryptoJS.enc.Utf8.parse(
            paymentRequest.name + ":" + paymentRequest.data.reference
          );
          const base64Reference = CryptoJS.enc.Base64.stringify(
            _wordsReference
          );
          
          if (!response.error) {
            this.resolveTransaction(
              paymentRequest,
              response,
              cardModel,
              base64Reference,
              debit,
              mixed_purchase
            );
          } else {
            PaymentezCheckout.close();
            const message =
              "Ocurrió un error procesando tu solicitud, intentalo de nuevo en unos minutos.";
            this.presentError(message);
          }
        }.bind(this)
      });
      this.openDialog(paymentRequest, mixed_purchase);
    }, 700);
  }

  private openDialog(paymentRequest: PaymentConfigModel, mixed_purchase): void {
    const _wordsReference = CryptoJS.enc.Utf8.parse(
      paymentRequest.name + ":" + paymentRequest.data.reference
    );
    const base64Reference = CryptoJS.enc.Base64.stringify(_wordsReference);
    PaymentezCheckout.open({
      user_id: uuid(),
      user_email: paymentRequest.data.email, // optional
      user_phone: paymentRequest.data.phone, // optional
      order_description: paymentRequest.data.description,
      order_amount: mixed_purchase ? paymentRequest.data.amount_aux_mixed_purchase : paymentRequest.data.amount,
      order_vat: paymentRequest.data.vat,
      order_reference: base64Reference,
      order_installments_type: 0
    });
  }

  private loadScript() {
    const node = document.createElement("script");
    node.src = url;
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
  }

  private resolveTransaction(
    paymentRequest: PaymentConfigModel,
    res: any,
    userCardPaymentezModel: UserCardPaymentezModel,
    base64Reference: string,
    debit: boolean,
    mixed_purchase: boolean
  ) {
    const auth = this.sessionStorageService.getItem<AuthModel>(
      SessionStorageService.AUTH
    );
    let transaction: PaymentTransactionModel;
    let retention;
    if(debit){
      retention = paymentRequest.retentions ? paymentRequest.retentions : null;
    }
    else{
      retention = null;
    }
    transaction = {
      client: paymentRequest.name,
      email: paymentRequest.data.email,
      payment_method: "CARD",
      amount: paymentRequest.aux_init_amount,
      authorization_code: res.transaction.authorization_code,
      carrier_code: res.transaction.carrier_code,
      dev_reference: paymentRequest.data.reference,
      id_transaction: res.transaction.id,
      message: res.transaction.message,
      description: paymentRequest.data.description,
      payment_date: res.transaction.payment_date,
      status: res.transaction.status,
      status_detail: res.transaction.status_detail,
      installments: res.transaction.installments,
      subclient: paymentRequest.data.subclient ? paymentRequest.data.subclient : null,
      franchise: res.card.type ? res.card.type : null,
      manual_bonus: paymentRequest.manual_bonus ? paymentRequest.manual_bonus : null,
      bonus_voucher: paymentRequest.manual_bonus_voucher ? paymentRequest.manual_bonus_voucher : null,
      id_product: paymentRequest.data.id_producto ? paymentRequest.data.id_producto : null,
      external_user_id : paymentRequest.data.external_user_id ? paymentRequest.data.external_user_id : null,
      token_external: paymentRequest.data.token_external ? paymentRequest.data.token_external : null,
      payment_reference: paymentRequest.payment_reference_status ? paymentRequest.payment_reference_status : paymentRequest.data.payment_reference,
      external_user_doc: paymentRequest.data.id,
      external_user_email: paymentRequest.data.email,
      products: paymentRequest.data.products ? paymentRequest.data.products : null,
      retentions: retention,
      mixed_purchase: mixed_purchase ? mixed_purchase : false,
      aux_init_amount_mixed_purchase: paymentRequest.data.amount_aux_mixed_purchase ? paymentRequest.data.amount_aux_mixed_purchase : null
    };
    
    this.authService.getTimezone().subscribe(
      success => {
        try {
          const key = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
          const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);

          const encrypted = CryptoJS.AES.encrypt(JSON.stringify({ cipher: success }), key, { mode: CryptoJS.mode.CTR, iv: iv, padding: CryptoJS.pad.NoPadding });
          transaction.hash = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
        } catch (error) {
          console.log(error);
        }

        this.paymentTransactionService
          .postTransaction(transaction, "Bearer " + auth.token)
          .subscribe(
            res => {
              this.responsePointsColombiaCard.emit(res)
              this.responseMixedPurchase.emit(res);
            },
            err => {
              console.log("Error: ", err);
            }
          );
        res.card.type = CreditCardPaymentezUtility.getValue(res.card.type);
        this.ngZone.run(() => {
          this._router.navigate(["/pagos/respuesta"], {
            state: { paymentRequest: paymentRequest, response: res }
          });
        });
      }
    );
  }

  presentError(message: any) {
    this._snackBar.openFromComponent(SnackMessageComponent, {
      data: message,
      duration: 5000,
      panelClass: ["snackError"]
    });
  }

  bulidJsonStatusAttempted(paymentRequest:PaymentConfigModel){
    let transaction: PaymentTransactionModel;
    transaction = {
      client: paymentRequest.name,
      payment_method: "CARD",
      external_user_id : paymentRequest.data.external_user_id ? paymentRequest.data.external_user_id : null,
      payment_reference: paymentRequest.payment_reference_status ? paymentRequest.payment_reference_status : paymentRequest.data.payment_reference
    };
    return transaction;
  }
  
  // openPurchasePaymentMix(paymentRequest: PaymentConfigModel, res: any) {

  //   const res_paymentez = res;

  //   this.responseMixedPurchase.subscribe(res_mixed => {
  //     console.log("soy la respuesta ", res);
  //     res.transaction.mixed_purchase = res_mixed.mixed_purchase;
  //     if (res.transaction.mixed_purchase.pending_value == 0) {
  //       this._router.navigate(["/pagos/respuesta"], {
  //         state: { paymentRequest: paymentRequest, response: res }
  //       });
  //     } else {
  //       const dialogRef = this.dialog.open(PurchasePaymentMixComponent, {
  //         width: "600px",
  //         data: {
  //           paymentRequest: paymentRequest,
  //           res: res,
  //         }
  //       });

  //       dialogRef.afterClosed().subscribe(result => {
  //         let url_retry = this.sessionStorageService.getItem(SessionStorageService.URL_PAYMENT_REQUEST);
  //         window.location.href = url_retry + "&reload=" + + new Date().getTime();
  //       });
  //     }
  //   })
  // }




}
