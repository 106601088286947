import {Component, Input, OnInit} from '@angular/core';
import {PaymentConfigModel} from '../../../../core/models/payment-config.model';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment';

import {Router} from '@angular/router';
import {SessionStorageService} from '../../../../core/services/session-storage.service';
import {AuthService} from '../../../../core/services/auth.service';
import {NequiService} from '../../../../core/services/nequi.service';
import {DatalayerService} from '../../../../core/services/datalayer.service';
import {PayClaroPayService} from './pay-claro-pay.service';
import {PaymentMethodService} from '../../../../core/services/payment-method.service';
import {ConfirmAppDialogComponent} from './confirm-app-dialog/confirm-app-dialog.component';
import {VerifyAppDialogComponent} from './verify-app-dialog/verify-app-dialog.component';
import * as publicIp from 'public-ip';
import {environment} from '../../../../../environments/environment';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AuthModel} from 'src/app/core/models/auth.model';
import {EncryptService} from '../../../../core/services/encrypt.service';
import {LoadingScreenService} from '../../../../core/services/loading-screen.service';
import { DataResponse} from './pay-claro-pay.interface';

@Component({
  selector: 'app-pay-claro-pay',
  templateUrl: './pay-claro-pay.component.html',
  styleUrls: ['./pay-claro-pay.component.scss']
})

export class PayClaroPayComponent implements OnInit {
  @Input() paymentRequest: PaymentConfigModel;
  loading: boolean;
  pendingNequiPayment = false;
  errorMessage: string;
  private ipAddress = '';
  isMobile = false;
  urlTPpagaClaro: SafeResourceUrl = '';
  isRedirected = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly serviceClaroPay: PayClaroPayService,
    private _router: Router,
    private sessionStorageService: SessionStorageService,
    private authService: AuthService,
    private nequiService: NequiService,
    private datalayerService: DatalayerService,
    private paymentMethodService: PaymentMethodService,
    private readonly sanitizer: DomSanitizer,
    private readonly encryptService: EncryptService,
    private loadingScreenService: LoadingScreenService
  ) {
    this.loading = false;
    publicIp.v4().then((data) => {
      this.ipAddress = data;
    }).catch(failure => {
      this.ipAddress = environment.defaultIp;
    });

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    this.nequiService.nequiPendingEmitter.subscribe(res => {
      this.pendingNequiPayment = res;
    });
    this.isRedirected = this.paymentRequest.data.status === 'REDIRECTED';
    if (this.isRedirected) {
      this.submitRequest();
    }
  }

  async submitRequest() {
    if (!this.isRedirected) {
      const modal = await this.openDialog();
      if (modal) {
        this.datalayerService.AddInfoDataLayerPaymentButton('si', 'GTW - confirmar proceso claropay');
        await this.createPaymentRequest();

        location.reload();
      } else {
        this.datalayerService.AddInfoDataLayerPaymentButton('no', 'GTW - confirmar proceso claropay');
      }
    } else {
      await this.openDialogVerify();
    }
  }

  async createPaymentRequest(): Promise<void> {
    this.errorMessage = '';
    this.loading = true;

    const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    const expirationDate = moment().add(1, 'd').toISOString();
    const urlOrigin = window.origin;
    //  const urlOrigin = 'https://dev-proyectos-pagos.ciencuadras.com';
    const dataOrder = this.sessionStorageService.getItem(SessionStorageService.ORDEN);
    const encryptedOrder = this.encryptService.encryptData(dataOrder);

    try {
      const responseEncrypted = await this.serviceClaroPay.createPaymentRequest({
        cost: this.paymentRequest.data.amount,
        idempotencyToken: this.paymentRequest.data.payment_reference,
        orderId: this.paymentRequest.data.reference,
        purchaseDescription: this.paymentRequest.data.description,
        purchaseDetailsUrl: `${urlOrigin}/pagos/respuesta?idempotencyToken=${this.paymentRequest.data.payment_reference}&data=${encryptedOrder}`,
        purchaseItems: [],
        terminalId: this.paymentRequest.name,
        userIpAddress: this.ipAddress,
        voucherUrl: `${urlOrigin}/pagos/respuesta?idempotencyToken=${this.paymentRequest.data.payment_reference}&data=${encryptedOrder}`,
        expiresAt: expirationDate
      }, auth.token).toPromise();

      const response: DataResponse = this.encryptService.decryptData(responseEncrypted.data);

      this.urlTPpagaClaro = this.sanitizer.bypassSecurityTrustResourceUrl(response.tpaga_payment_url);
      setTimeout(() => {
        if (this.urlTPpagaClaro) {
          document.getElementById('urlClaro').click();
        }
      }, 0);

      this.loading = false;
    } catch (error) {
      const errorResponse = error.error;
      if (errorResponse.code === '409') {
        if (errorResponse.error.data) {
          const errorData: DataResponse = this.encryptService.decryptData(errorResponse.error.data);
          const urlTpaga = errorData.tpaga_payment_url;
          this.urlTPpagaClaro = this.sanitizer.bypassSecurityTrustResourceUrl(urlTpaga);
          setTimeout(() => {
            if (this.urlTPpagaClaro) {
              document.getElementById('urlClaro').click();
            }
          }, 0);
        }
      } else {
        this.errorMessage = errorResponse.error.error_message ? errorResponse.error.error_message : errorResponse;
      }

      console.log(error);

    } finally {
      this.loading = false;
      await this.openDialogVerify();
    }
  }

  openDialog() {

    return new Promise<any>((resolve, reject) => {
      const modal = this.dialog.open(ConfirmAppDialogComponent, {
        minWidth: '350px',
        maxWidth: '400px',
      });

      modal.afterClosed()
        .subscribe((confirm) => {
          if (confirm) {
            resolve(true);
            return true;
          } else {
            resolve(false);
            return false;
          }
        });
    });
  }

  async openDialogVerify() {

    return new Promise<any>((resolve, reject) => {
      const modalConfirmation = this.dialog.open(VerifyAppDialogComponent, {
        minWidth: '350px',
        maxWidth: '400px',
        disableClose: true
      });

      modalConfirmation.afterClosed()
        .subscribe((confirm) => {
          this.ConfirmStatus(confirm);
          if (confirm) {
            this.datalayerService.AddInfoDataLayerPaymentButton('si', 'GTW - confirmar proceso claropay');
          } else {
            this.datalayerService.AddInfoDataLayerPaymentButton('no', 'GTW - confirmar proceso claropay');
          }
        });
    });
  }

  async ConfirmStatus(redirect: boolean = false) {
    this.loadingScreenService.startLoading();
    const domain = document.location.origin;
    const res = await this.authService.login(domain).toPromise();
    const auth = res;
    const token = auth.token;
    const reference = this.paymentRequest.data.payment_reference;

    this.serviceClaroPay.confirmStatus(reference, token).subscribe(
      responseEncrypted => {
        const response: DataResponse = this.encryptService.decryptData(responseEncrypted.data);
        if (response.status === 'paid' || response.status === 'delivered') {
          window.open(response.purchase_details_url, '_self');
          return;
        }
        if (redirect) {
          window.open(response.tpaga_payment_url, '_self');
          return;
        }
      },
      err => {
        console.log(err);
        this.loadingScreenService.stopLoading();
        if (err.error.error) {
          console.log(err.error.error);
          this._router.navigate(['/pagos/mensaje'], {state: {message: err.error.error.error_message}});
        }
      },
      () => {
        this.loadingScreenService.stopLoading();
      }
    );
  }
  disabledPaymentMethod() {
    if (this.pendingNequiPayment) {
      return true;
    }
    if (this.paymentRequest.data.requires_split_payment == true && this.paymentRequest.data.dataSplitTransactions.length && this.paymentRequest.gateway_active == 'Paymentez') {
      if(this.paymentRequest.data.subclient){
        return false;
      }
      return true;
    }
    return false;
  }
}
