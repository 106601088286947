import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

import { PaymentConfigModel } from '../../../../core/models/payment-config.model';

import { PayDiraService } from './pay-dira.service';
import { DiraIframeComponent } from './components/dira.iframe.component';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NequiService } from 'src/app/core/services/nequi.service';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import { AuthModel } from 'src/app/core/models/auth.model';
@Component({
  selector: 'app-pay-dira',
  templateUrl: './pay-dira.component.html',
  styleUrls: ['./pay-dira.component.scss'],
})
export class PayDiraComponent implements OnInit {
  @Input() paymentRequest: PaymentConfigModel;
  loading: boolean;
  pendingNequiPayment = false;
  errorMessage: string;
  constructor(
    private readonly dialog: MatDialog,
    private readonly service: PayDiraService,
    private _router: Router,
    private sessionStorageService: SessionStorageService,
    private _authService: AuthService,
    private nequiService: NequiService,
    private datalayerService: DatalayerService
  ) {
    this.loading = false;
  }

  ngOnInit(): void {
    this.nequiService.nequiPendingEmitter.subscribe(res => {
        this.pendingNequiPayment = res;
    })
    // this.paymentRequest.diraPayActive = true;
  }

  async createInvoice(): Promise<void> {
    this.errorMessage = '';
    let auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    try {
      this.loading = true;
      this.datalayerService.checkoutEvent('5', this.paymentRequest);
      const response = await this.service.createInvoice({
        reference: this.paymentRequest.data.reference,
        value: this.paymentRequest.data.amount,
        description: this.paymentRequest.data.description,
        currency: 'COP',
        requirePayerInfo: true
      }, auth.token).toPromise();

      let url = response.url;
      window.location.replace(url)
      this.loading = false;
    } catch (error) {
      console.log(error.error.error);
      this.errorMessage = error.error.error;
      this.loading = false;
    }
  }

  disabledPaymentMethod() {
    if (this.pendingNequiPayment) {
      return true;
    }
    if (this.paymentRequest.data.requires_split_payment == true && this.paymentRequest.data.dataSplitTransactions.length && this.paymentRequest.gateway_active == 'Paymentez') {
      if(this.paymentRequest.data.subclient){
        return false;
      }
      return true;
    }
    return false;
  }
}
